<template>
    <div id="protected-content">
        <div
            v-if="book && book.tendrilProduct"
            ref="tendril-container"
            class="p-relative d-flex align-start justify-center scroll-hide"
            style="height: 100%; width: 100%; overflow: auto">
            <div class="position-container flex-grow-0 flex-shrink-0" :style="positionContainerStyle()">
                <div class="scale-container p-relative" :style="scaleContainerStyle()">
                    <iframe
                        v-show="!loading"
                        id="tendrilFrame"
                        class="tendrilViewer"
                        :style="iFrameStyle()"
                        :src="sourceURL"
                        frameborder="0"
                        allowfullscreens
                        allow="autoplay"></iframe>
                </div>
            </div>
        </div>

        <div v-else-if="isValidURL(sourceURL)" class="story-container">
            <div class="story-position">
                <iframe class="interactive-story-viewer" :src="sourceURL" frameborder="0" allowfullscreens allow="autoplay"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

let watcher;

export default {
    name: "TendrilComponent",
    data() {
        return {
            sourceURL: "",
            loading: true,
            collection: null,

            scale: 1,
            iFrameWidth: 0,
            iFrameHeight: 0,

            screenWidth: 0,
            screenHeight: 0,
        };
    },
    components: {},

    props: [],
    watch: {
        sourceURL(newValue, oldValue) {
            console.warn("Source URL:", newValue);
        },
    },

    computed: {
        ...mapState(["libraryCollection"]),
        book() {
            return this.collection?.data?.items.find((item) => item?.title == this.$route?.params?.id);
        },
    },
    methods: {
        iFrameStyle() {
            return {
                position: `absolute`,
                top: 0,
                left: 0,
                width: `${this.iFrameWidth}px`,
                height: `${this.iFrameHeight}px`,
            };
        },
        scaleContainerStyle() {
            this.scale = this.screenWidth / this.iFrameWidth;

            return {
                width: `${this.iFrameWidth}px`,
                height: `${this.iFrameHeight}px`,
                transform: `scale(${this.scale})`,
                transformOrigin: "0% 0%",
            };
        },

        setWindowSize() {
            console.warn("Setting Scale!");
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
        },

        positionContainerStyle() {
            return {
                width: `${this.iFrameWidth * this.scale}px`,
                height: `${this.iFrameHeight * this.scale}px`,
            };
        },

        getImage(image) {
            return `${this.$fluro.asset.imageUrl(image)}&noRedirect=true`;
        },
        handlerError() {
            console.warn("Iframe Error!");
            this.loading = true;
        },
        isValidURL(string) {
            //Checks if source url contains http or https
            const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(:[0-9]+)?(\/.*)?$/;
            return pattern.test(string);
        },
    },
    async mounted() {
        this.$nextTick(() => {
            document.addEventListener("contextmenu", (event) => {
                event.preventDefault();
            });
        });

        this.loading = true;
        this.collection = await this.$fluro.content.get("643e0266b664306ce6d0b73e");
        //is book premium? If so, does user have access? Don't load premium content if user doesn't have premium access
        if (this.book?.isPremium && !this.$app.user?.isPremium) {
            this.loading = false;
            this.$router.push({ name: "payment" });
        }

        //Check if book has a Tendril Component OR Interactive Story
        if (this.book?.tendrilProduct) {
            const tendrilProductId = this.book?.tendrilProduct?._id;
            const resize = _.debounce(() => {
                this.setWindowSize();
            }, 1000);
            window.addEventListener("resize", resize);
            this.setWindowSize();

            let product = await this.$fluro.content.get(tendrilProductId);
            console.log("THE PRODUCT", product);
            let appBookPackage = (product?.items || []).find(({ definition }) => definition == "appBookPackage");
            console.log("THE appbookPackage", appBookPackage);

            try {
                let project = appBookPackage
                    ? await this.$fluro.api
                          .get(`package/get/${appBookPackage._id}/project.json?from=${tendrilProductId}`)
                          .then(({ data }) => data)
                    : null;

                const { width, height } = project.data.layouts[0];
                this.iFrameWidth = width;
                this.iFrameHeight = height;
            } catch (err) {
                return console.log("ERROR (product):", err);
            }

            //Send token to to Red Sparrow Tendril Viewer to retrieve Tendril Project
            // this.sourceURL = `https://rsb.tendril.com.au/view/${
            //     this.book?.tendrilProduct
            // }?token=${this.$fluro.auth.getCurrentToken()}`;

            this.sourceURL = `https://iyp-education.s3.ap-southeast-2.amazonaws.com/deployments/au.com.redsparrow.tendrilviewer/master/index.html#/view/${tendrilProductId}?token=${this.$fluro.auth.getCurrentToken()}`;

            // this.sourceURL = `https://redsparrow.tendril.com.au/view/${
            //     this.book?.tendrilProduct
            // }?token=${this.$fluro.auth.getCurrentToken()}`;

            // const tendrilFrame = document.getElementById("tendrilFrame");
            // const payload = {
            //     token: this.$fluro.auth.getCurrentToken(),
            // };
            // tendrilFrame.contentWindow.postMessage(payload, "https://rsb.tendril.com.au/view/");
        } else if (this.book?.interactiveStoryLink) {
            console.log("Loading interactive story...");
            this.sourceURL = this.book?.interactiveStoryLink;
        } else {
            console.warn("The book has NO Component!");
        }

        this.scaleContainerStyle();
        this.loading = false;
    },
    destroyed() {
        window.removeEventListener("resize", watcher);
    },
};
</script>

<style lang="scss">
#protect-content {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.story-container {
    position: relative;
    width: 100%;
}

.story-position {
    position: relative;
    margin: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
    max-height: calc(100vh - 12vh);
}
.interactive-story-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

// .interactive-story-viewer {
//     height: auto;
//     width: 100%;

//     //  @media (orientation: landscape) and (max-width: 600px) {
//     //      height: 100%;
//     //      width: auto;
//     //  }
//     aspect-ratio: 16 / 9;

//     // height: 100%;
//     // width: 100%;

//     //  position: absolute;

//     //  @media (min-width: 900px) {
//     //      min-width: 1200px;
//     //  }

//     //  @media (min-width: 1200px) {
//     //      min-width: 1500px;
//     //  }
// }
</style>
